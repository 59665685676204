import { StateCreator } from 'zustand'
import { ToastSlice } from './toast-slice'
import { PERMISSION_MODULE } from '~/core/constants/enum'

export interface ITenantPermissionSettingType {
  visibility: boolean
  moduleName: string
  kind: string
  toggleable: boolean
  enabled: boolean
  objects: Array<{
    name: string
    enabled: boolean
    actions: Array<string>
    permissions: Array<string>
    visibility?: boolean
  }>
}

export interface TenantPermissionSettingSlice {
  permissionSetting: ITenantPermissionSettingType[]
  setPermissionSetting: (
    permissionSetting: ITenantPermissionSettingType[]
  ) => void
  reportPermission: ITenantPermissionSettingType | undefined
}

export const createTenantPermissionSettingSlice: StateCreator<
  ToastSlice & TenantPermissionSettingSlice,
  [],
  [],
  TenantPermissionSettingSlice
> = (set: Function) => ({
  permissionSetting: [],
  reportPermission: undefined,
  setPermissionSetting: (permissionSetting: ITenantPermissionSettingType[]) =>
    set({
      permissionSetting,
      reportPermission: permissionSetting.find((fi) =>
        [PERMISSION_MODULE.report].includes(fi.moduleName)
      )
    })
})

import { create } from 'zustand'
import { BulkActionSlice, createBulkActionSlice } from './bulk-action-slice'
import {
  createTenantFeatureSettingSlice,
  TenantFeatureSettingSlice
} from './feature-setting-slice'
import { createFilterSlice, FilterSlice } from './filter-slice'
import {
  createLoadingBlockAppSlice,
  LoadingBlockAppSlice
} from './loading-block-app-slice'
import {
  createMyDeleteSlice,
  MyDeleteRefeshSlice
} from './my-list-delete-slice'
import { createMyListSlice, MyListSlice } from './my-list-slice'
import {
  createTenantPermissionSettingSlice,
  TenantPermissionSettingSlice
} from './permission-setting-slice'
import { createTasksSlice, TasksSlice } from './tasks-drawer-slice'
import { createToastSlice, ToastSlice } from './toast-slice'
import { createUserDevicesSlice, UserDevicesSlice } from './user-devices-slice'
import { createUserRoleSlice, UserRoleSlice } from './user-role-slice'
import { createUserSlice, UserSlice } from './user-slice'

const useBoundStore = create<
  ToastSlice &
    UserSlice &
    UserRoleSlice &
    UserDevicesSlice &
    TasksSlice &
    MyDeleteRefeshSlice &
    LoadingBlockAppSlice &
    MyListSlice &
    TenantPermissionSettingSlice &
    TenantFeatureSettingSlice &
    BulkActionSlice &
    FilterSlice
>()((...a) => ({
  ...createLoadingBlockAppSlice(...a),
  ...createUserSlice(...a),
  ...createUserRoleSlice(...a),
  ...createUserDevicesSlice(...a),
  ...createToastSlice(...a),
  ...createTasksSlice(...a),
  ...createMyListSlice(...a),
  ...createTenantPermissionSettingSlice(...a),
  ...createTenantFeatureSettingSlice(...a),
  ...createMyDeleteSlice(...a),
  ...createBulkActionSlice(...a),
  ...createFilterSlice(...a)
}))

export default useBoundStore

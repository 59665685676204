import { StateCreator } from 'zustand'
import { ToastSlice } from './toast-slice'

export interface BulkActionSlice {
  bulkValues?: string[]
  bulkSelectedAll?: boolean
  setBulkValues: (val: string[]) => void
  setBulkSelectedAll: (val: boolean) => void
  resetBulkValues: () => void
}

export const createBulkActionSlice: StateCreator<
  ToastSlice & BulkActionSlice,
  [],
  [],
  BulkActionSlice
> = (set: Function) => ({
  bulkValues: [],
  bulkSelectedAll: false,
  setBulkValues: (val: string[]) => set({ bulkValues: val }),
  setBulkSelectedAll: (val: boolean) => set({ bulkSelectedAll: val }),
  resetBulkValues: () => set({ bulkValues: [], bulkSelectedAll: false })
})

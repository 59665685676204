import { StateCreator } from 'zustand'
import { UserSlice } from './user-slice'

export interface LoadingBlockAppSlice {
  showLockApp: boolean
  titleLockApp: string
  setShowLockApp: (titleLockApp: string) => void
  setCloseLockApp: () => void
}

export const createLoadingBlockAppSlice: StateCreator<
  UserSlice & LoadingBlockAppSlice,
  [],
  [],
  LoadingBlockAppSlice
> = (set: Function) => ({
  showLockApp: false,
  titleLockApp: '',
  setShowLockApp: (titleLockApp: string) =>
    set({ showLockApp: true, titleLockApp }),
  setCloseLockApp: () => set({ showLockApp: false, titleLockApp: '' })
})

import { StateCreator } from 'zustand'
import { IRole } from '~/core/@types/global'
import { ToastSlice } from './toast-slice'

export interface UserRoleSlice {
  currentRole?: IRole
  setCurrentRole: (user: IRole) => void
}

export const createUserRoleSlice: StateCreator<
  ToastSlice & UserRoleSlice,
  [],
  [],
  UserRoleSlice
> = (set: Function) => ({
  currentRole: undefined,
  setCurrentRole: (currentRole: IRole) => set({ currentRole })
})
